body {
  background: #0e0a1f;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* center, responsive & round corners image */
.center-img {
  border-radius: 14px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 28%;
  height: auto;
}

.left-padded-white {
  padding-left: 14px;
  color: #fff;
}

h2 {
  color:#fff;
  text-align: center;
}

.heart {
  color: #f00;
}
